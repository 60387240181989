* {
  box-sizing: border-box;
}

html,
body,
#root {
  font-size: 16px;
  min-height: 100%;
  max-width: 590px;
  margin: auto;
  font-family: 'Inter', sans-serif;
}

#root {
  padding-top: 30px;
}

.app-container {
  padding: 0 20px;
}

/* Typography */

h1 {
  font-size: 28px;
  font-weight: 400;
}

h2 {
  font-size: 22px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

p.bold {
  font-weight: bold;
}

p.secondary {
  color: #767676;
}

p.collapse {
  margin: 0;
}

a {
  text-decoration: none;
  color: initial;
}
